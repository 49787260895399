<template>
  <div>
    <div class="bg-info p-3 mb-2">
      <p class="m-0">
        The notice you add below will show up for visitors on their initial visit after the creation of this notice.
      </p>  
    </div>
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
      <!-- b-select 
        v-model="notice.options.variant"
        :options="[{ text: 'Red', value: 'danger' }, { text: 'Yellow', value: 'warning' }, { text: 'Green', value: 'success' }]"
        class="autowidth mb-4"
      ></b-select><br / -->
      <b-input
        v-model="notice.title"
        required
      ></b-input>
      <b-textarea
        v-model="notice.content"
        rows="2"
        max-rows="8"
        placeholder="Place your notice here."
        required
      ></b-textarea><br />
      <div>
        <b-btn type="reset" variant="danger" :disabled="!notice.content">Reset / Remove</b-btn>
        <b-btn type="submit" class="float-right" variant="success" :disabled="!notice.content">{{ saveBtnText }}</b-btn>
      </div>
    </b-form>

    <hr />

    <b-row v-if="notice.content" class="animated fadeIn m-2">
      <b-col>
        <b-row class="p-2" :class="'bg-' + notice.options.variant" align-v="center">
          <b-col class="text-center d-none d-md-block">
            <i class="fa fa-exclamation text-light" style="font-size:7em"></i>
          </b-col>
          <b-col cols="12" md="9">
            <h4 class="text-white">{{ notice.title }}</h4>
            <p class="text-white" v-html="notice.content.replace(/\n/g, '<br />')"></p>
          </b-col>
          <b-col class="mr-2">
            <b-button class="mx-auto my-auto" variant="light" block>OK</b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="3"></b-col>
    </b-row>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const CONTENT_TYPE = 'public_notice'
const NOTICE_TPL = {
  title: 'Important!',
  content: '',
  options: {
    variant: 'danger'
  }
}
export default {
  data () {
    return {
      notice: {
        title: null,
        content: null,
        options: {
          variant: null
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'content'
    ]),
    saveBtnText () {
      return !this.notice.id ? 'Save' : 'Update'
    }
  },
  methods: {
    ...mapActions([
      'getContent',
      'postContent',
      'putContent',
      'deleteContent',
      'resetContent'
    ]),
    async onSubmit () {

      try {
        if (!this.notice.id) {
          await this.postContent({
            type: CONTENT_TYPE,
            slug: this.$moment().format('YYYYMMDD'),
            business: this.currentBusiness['@id'],
            author: this.currentUser['@id'],
            dataCreated: this.$moment().format(),
            dateStart: this.$moment().format(),
            title: this.notice.title,
            content: this.notice.content,
            options: {
              variant: this.notice.options.variant
            }
          })
        } else {
          await this.putContent({
            id: this.notice.id,
            dateStart: this.$moment().format(),
            title: this.notice.title,
            content: this.notice.content,
            options: {
              variant: this.notice.options.variant
            }
          })
        }
      } catch (err) {
        console.log(err)
      }

      this.showSuccess('The notice has been saved.')
    },
    onReset () {
      this.$bvModal.msgBoxConfirm('Do you want to reset this message?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(confirm => {
        if (confirm) {
          if (this.notice.id) {
            this.deleteContent(this.notice.id)
          }
          this.notice = { ...NOTICE_TPL }
        }
      })
    }
  },
  async mounted () {
    await this.getContent({
      business: this.currentBusiness.id,
      type: CONTENT_TYPE
    })

    if (this.content.length) {
      this.notice = this.content[0]
    } else {
      this.notice = { ...NOTICE_TPL }
    }
  },
  destroyed () {
    this.resetContent()
  }
}
</script>